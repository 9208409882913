import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingBox, TransactionStatusType } from "suregifts-component-lib";
import { useRetrievePaymentData } from "../../../hooks/useRetrievePaymentData";
import { useVerify } from "../../../services/transaction";
import VerifySuccessView from "./VerifySuccessView";
import FailedVerificationView from "./FailedVerificationView";

function DonationPaymentCallback() {
  const { invoiceNumber, paymentMethod, providerRef } = useRetrievePaymentData();
  const { mutate: verifyPayment, data } = useVerify();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sending, setSending] = useState(false);
  useEffect(() => {
    if (!sending) {
      setSending(true);
      if (pathname.indexOf("/payment_callback") === -1) {
        navigate("/");
        return;
      }
      if ((!invoiceNumber && !providerRef) || !paymentMethod) {
        navigate("/");
        return;
      }
      const paymentModel = { invoiceNumber, providerRef, paymentMethod };
      verifyPayment(paymentModel, {});
    }
  }, []);

  if (!data) return <LoadingBox loading loadingPosition="center" />;
  if (data.statusId === TransactionStatusType.Successful) return <VerifySuccessView amountPaid={data.amountPaid} />;
  return <FailedVerificationView />;
}

export default DonationPaymentCallback;
