import React from "react";

function PasswordRequirment() {
  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ fontSize: "14px", fontStyle: "italic" }}>
        <ul
          style={{
            padding: "0 16px ",
            margin: "0",
          }}
        >
          <li>At least 8 characters long</li>
          <li>At least one uppercase letter (A-Z)</li>
          <li>At least one lowercase letter (a-z)</li>
          <li>At least one number (0-9)</li>
          <li>At least one special character (!@#$%^&*)</li>
        </ul>
      </div>
    </div>
  );
}

export default PasswordRequirment;
