import { Outlet, createBrowserRouter } from "react-router-dom";
import LayoutContainer from "../layout/LayoutContainer";
import HomeScreen from "../pages/home/HomeScreen";
import TermsAndConditionScreen from "../pages/static/terms-and-condition/TermsAndConditionScreen";
import ProfileLandingScreen from "../pages/profile/ProfileLandingScreen";
import { RequireAuth } from "./RequireAuth";

import BlogInfoScreen from "../pages/static/blog-info/BlogInfoScreen";
import BlogScreen from "../pages/static/blogs/BlogScreen";
import ContactSupportScreen from "../pages/static/contact-support/ContactSupportScreen";
import FaqScreen from "../pages/static/faq/FaqScreen";
import HowToUseScreen from "../pages/static/how-to-use/HowToUseScreen";
import MerchantRegisterScreen from "../pages/static/merchant-register/MerchantRegisterScreen";
import MerchantScreen from "../pages/static/merchant/MerchantScreen";
import { AccountSettingContextProvider } from "../pages/profile/account-settings/AccountSettingContext";
import { ContactContextProvider } from "../pages/profile/contact/ContactContext";
import ContactLanding from "../pages/profile/contact/ContactLanding";
import ContactAddEditScreen from "../pages/profile/contact/add-edit/ContactAddEditScreen";
import ContactScreen from "../pages/profile/contact/list/ContactScreen";
import OrderInfoScreen from "../pages/profile/order-info/OrderInfoScreen";
import MyOrderScreen from "../pages/profile/order/MyOrderScreen";
import CheckBalanceScreen from "../pages/profile/voucher-services/check-balance/CheckBalanceScreen";
import VoucherListScreen from "../pages/profile/voucher/list/VoucherListScreen";
import VoucherInfoScreen from "../pages/profile/voucher/voucher-info/VoucherInfoScreen";
import { WalletContextProvider } from "../pages/profile/wallet/WalletContext";
import VoucherServiceLanding from "../pages/profile/voucher-services/VoucherServiceLanding";
import VoucherMergeScreen from "../pages/profile/voucher-services/merge/VoucherMergeScreen";

import VoucherSplitScreen from "../pages/profile/voucher-services/split/VoucherSplitScreen";
import VoucherSwapScreen from "../pages/profile/voucher-services/swap/VoucherSwapScreen";
import GiftCardsScreen from "../pages/gift-cards/GiftCardsScreen";
import AirtimeListScreen from "../pages/utility/airtime/list/AirtimeListScreen";
import BillListScreen from "../pages/utility/bills/list/BillListScreen";
import BillCheckoutScreen from "../pages/utility/bills/checkout/BillCheckoutScreen";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import { CartContextProvider } from "../pages/cart/checkout/CartContext";
import { CartDeliveryContextProvider } from "../pages/cart/delivery/useCartDeliveryContext";
import { CartPersonalizeContextProvider } from "../pages/cart/personalize/CartPersonalizeContext";
import PaymentCallbackScreen from "../pages/payment-callback/PaymentCallbackScreen";

import CartCompletedScreen from "../pages/cart/completed/CartCompletedScreen";
import VoucherReleaseScreen from "../pages/profile/voucher-services/release/VoucherReleaseScreen";
import BillPaymentsScreen from "../pages/profile/order/BillPaymentsScreen";
import AirtimeCheckoutScreen from "../pages/utility/airtime/checkout/AirtimeCheckoutScreen";
import MerchantLocationScreen from "../pages/locations/MerchantLocationScreen";
import PrivacyScreen from "../pages/static/privacy/PrivacyScreen";
import { GiftInfoDialog } from "../pages/gift-cards/GiftInfoDialog";
import LoginModal from "../modals/LoginModal";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import RegisterModal from "../modals/RegisterModal";
import ForgotPassword from "../modals/ForgotPassword";
import AddVoucherModal from "../pages/profile/voucher/modals/AddVoucherModal";
import NotFoundPage from "../layout/NotFoundPage";
import ServerErrorPage from "../layout/ServerErrorPage";
import PhoneVerificationModal from "../modals/PhoneVerificationModal";
import { AccountContextProvider } from "../pages/profile/account/AccountContext";
import { ScrollToTop, UtilityContentProvider } from "suregifts-component-lib";
import EmailVerificationModal from "../modals/EmailVerificationModal";
import AddPhoneNumberModal from "../modals/AddPhoneNumberModal";
import DominoPromoScreen from "../pages/dominos/DominoPromoScreen";
import TransferVoucherScreen from "../pages/profile/voucher-services/transfer/TransferVoucherScreen";
import RegistryScreen from "../pages/registry/RegistryScreen";
import DonateModal from "../pages/registry/DonateModal";
import DonationPaymentCallback from "../pages/registry/payment-callback/DonationPaymentCallback";

export const router = createBrowserRouter([
  {
    element: <Outlet />,
    children: [
      {
        errorElement: <NotFoundPage />,
        children: [
          {
            path: "server-error",
            element: <ServerErrorPage />,
          },
          {
            path: "dominos-promo",
            element: <DominoPromoScreen />,
          },
          { path: "/registry/:urlPath/payment_callback/:type", element: <DonationPaymentCallback /> },
          {
            path: "payment_callback/:type",
            element: (
              <GlobalContextProvider>
                <PaymentCallbackScreen />
              </GlobalContextProvider>
            ),
          },
          {
            path: "/cart/completed",
            element: (
              <GlobalContextProvider>
                <CartCompletedScreen />
              </GlobalContextProvider>
            ),
          },
          {
            element: (
              <ScrollToTop>
                <GlobalContextProvider>
                  <LayoutContainer />
                </GlobalContextProvider>
              </ScrollToTop>
            ),
            children: [
              {
                path: "/",
                element: <HomeScreen />,
                children: [
                  { path: "login", element: <LoginModal /> },
                  { path: "reset-password", element: <ResetPasswordModal /> },
                  { path: "register", element: <RegisterModal /> },
                  { path: "forgot-password", element: <ForgotPassword /> },
                  {
                    path: "funding_callback/:type",
                  },
                  { path: "verify-email", element: <EmailVerificationModal /> },
                  {
                    path: "verify-phone",
                    element: <PhoneVerificationModal />,
                  },
                  {
                    path: "add-phone",
                    element: <AddPhoneNumberModal />,
                  },
                ],
              },
              {
                path: "/registry/:urlPath",
                element: <RequireAuth children={<RegistryScreen />} />,
                children: [{ path: "donate", element: <RequireAuth children={<DonateModal />} /> }],
              },

              {
                path: "/merchant",
                element: <MerchantScreen />,
              },
              {
                path: "/merchant-register",
                element: <MerchantRegisterScreen />,
              },
              {
                path: "/contact-support",
                element: <ContactSupportScreen />,
              },
              { path: "/locations", element: <MerchantLocationScreen /> },
              {
                path: "/faq",
                element: <FaqScreen />,
              },
              {
                path: "/privacy-policy",
                element: <PrivacyScreen />,
              },
              {
                path: "/terms-and-condition",
                element: <TermsAndConditionScreen />,
              },
              {
                path: "/how-to-use",
                element: <HowToUseScreen />,
              },
              {
                path: "/blogs",
                element: <BlogScreen />,
              },
              {
                path: "/blogs/:blogId",
                element: <BlogInfoScreen />,
              },
              {
                path: "/gift-cards",
                element: <GiftCardsScreen />,
                children: [{ path: ":giftId", element: <GiftInfoDialog /> }],
              },
              {
                element: <RequireAuth children={<ProfileLandingScreen />} />,
                children: [
                  {
                    path: "/account",
                    element: <AccountContextProvider />,
                    children: [
                      { path: "verify-email", element: <EmailVerificationModal /> },
                      {
                        path: "verify-phone",
                        element: <PhoneVerificationModal />,
                      },
                    ],
                  },

                  {
                    path: "vouchers",
                    element: <VoucherListScreen />,
                    children: [
                      {
                        path: "add",
                        element: <AddVoucherModal />,
                      },
                    ],
                  },
                  { path: "/vouchers/:voucherCode", element: <VoucherInfoScreen /> },
                  {
                    path: "/voucher-services",
                    element: <VoucherServiceLanding />,
                    children: [
                      { element: <CheckBalanceScreen />, index: true },
                      { element: <CheckBalanceScreen />, path: "check-balance" },
                      { element: <VoucherMergeScreen />, path: "merge" },
                      { element: <VoucherSplitScreen />, path: "split" },
                      { element: <VoucherSwapScreen />, path: "swap" },
                      { element: <TransferVoucherScreen />, path: "transfer" },

                      { element: <VoucherReleaseScreen />, path: "release" },
                    ],
                  },
                  {
                    path: "/orders",
                    element: <MyOrderScreen />,
                  },
                  {
                    path: "/orders/:orderId",
                    element: <OrderInfoScreen />,
                  },
                  {
                    path: "/orders/bills",
                    element: <BillPaymentsScreen />,
                  },
                  {
                    path: "/wallet",
                    element: <WalletContextProvider />,
                  },

                  {
                    path: "/contacts",
                    element: (
                      <ContactContextProvider>
                        <ContactLanding />
                      </ContactContextProvider>
                    ),
                    children: [
                      {
                        index: true,
                        element: <ContactScreen />,
                      },
                      {
                        path: "edit/:contactId",
                        element: <ContactAddEditScreen />,
                      },
                      {
                        path: "add",
                        element: <ContactAddEditScreen />,
                      },
                    ],
                  },
                  {
                    path: "/account-settings",
                    element: <AccountSettingContextProvider />,
                  },
                ],
              },
              {
                path: "/airtime",
                element: <AirtimeListScreen />,
              },
              {
                path: "/airtime/:airtimeId",
                element: (
                  <RequireAuth>
                    <UtilityContentProvider>
                      <AirtimeCheckoutScreen />
                    </UtilityContentProvider>
                  </RequireAuth>
                ),
              },

              {
                path: "/bills",
                element: <BillListScreen />,
              },
              {
                path: "/bills/:billerId",
                element: (
                  <RequireAuth>
                    <UtilityContentProvider>
                      <BillCheckoutScreen />
                    </UtilityContentProvider>
                  </RequireAuth>
                ),
              },
              {
                path: "/cart",
                children: [
                  { index: true, element: <CartContextProvider /> },
                  { path: "personalize", element: <CartPersonalizeContextProvider /> },
                  { path: "delivery-method", element: <CartDeliveryContextProvider /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
