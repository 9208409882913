import { Box, Container, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AmountSummaryCard, BillFormSection, TransactionType, WalletType, useDocumentTitle, useUtilityContent } from "suregifts-component-lib";
import { BillsPaymentMethodView } from "../../PaymentMethodView";
import { useEffect } from "react";

function BillCheckoutScreen() {
  useDocumentTitle("Bills Checkout");

  const theme = useTheme();
  const {
    form: { setValue },
  } = useUtilityContent();

  useEffect(() => {
    setValue("billTypeId", TransactionType.BillsPayment);
    setValue("paymentMethodId", WalletType.Voucher);
  }, []);

  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 400px ",
          },
        }}
      >
        <div>
          <BillFormSection />
          <div style={{ marginTop: "30px" }}>
            Note: For mobile data, Customer No. must begin with <i>234</i> or <i>254</i>
          </div>
        </div>
        <Stack spacing={3}>
          <AmountSummaryCard />
          <BillsPaymentMethodView />
        </Stack>
      </Box>
    </Container>
  );
}

export default BillCheckoutScreen;
